import { Container, Skeleton } from '@mui/material';
import type { Event, Group, PaginatedList, Person } from 'core';
import type { FC } from 'react';
import { ErrorView } from '../../components/common/error.js';
import type { UseAxiosResult } from '../../hooks/use-axios.js';
import { NotFoundPage } from '../not-found/page.js';
import type { GroupActionNames, PersonActionNames } from './group-details.js';
import { GroupDetailsView } from './group-details.js';

interface Props {
  edit: boolean;
  events: UseAxiosResult<PaginatedList<Event.WithCourses> | undefined>;
  group: UseAxiosResult<Group.Type | undefined>;
  groupActions: Record<GroupActionNames, () => void>;
  person: Person.Type;
  personActions: Record<PersonActionNames, (personId: string) => () => void>;
  refresh: () => void;
}

export const GroupPageView: FC<Props> = ({
  edit,
  events,
  group: { data, error, loading },
  groupActions,
  person,
  personActions,
  refresh,
}) => (
  <Container maxWidth="md" sx={{ my: 3 }}>
    {loading ? (
      <Skeleton variant="rounded" height={60} width="100%" />
    ) : error ? (
      <ErrorView error={error} />
    ) : !data ? (
      <NotFoundPage />
    ) : (
      <GroupDetailsView
        groupActions={groupActions}
        data={data}
        events={events}
        edit={edit}
        person={person}
        personActions={personActions}
        refresh={refresh}
      />
    )}
  </Container>
);
