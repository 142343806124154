import { captureException } from '@sentry/react';
import axios from 'axios';
import type { Event, Group, PaginatedList } from 'core';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import uri from 'uri-tag';
import { useAuthenticated } from '../../auth/auth-provider.js';
import { useAxios } from '../../hooks/use-axios.js';
import { isAdmin, isMember } from './utils.js';
import { GroupPageView } from './view.js';

const Controller: FC = () => {
  const { person } = useAuthenticated();
  const { enqueueSnackbar } = useSnackbar();

  const [edit, setEdit] = useState(false);
  const { id } = useParams();

  const [group, refresh] = useAxios<Group.Type>(id && uri`/api/v1/group/${id}`);
  const [events] = useAxios<PaginatedList<Event.WithCourses>>(
    group.data &&
      (isMember(group.data, person) || isAdmin(group.data, person)) &&
      id &&
      uri`/api/v1/group/${id}/events?sort=-start`,
  );

  const api = (url: string, data: unknown): void => {
    axios
      .post(url, data)
      .then(() => {
        refresh();
        return;
      })
      .catch((err) => {
        captureException(err);
        enqueueSnackbar('Unable to update group', { variant: 'error' });
        refresh();
      });
  };

  return GroupPageView({
    groupActions: {
      rename: () => setEdit(true),
      leave: () => api(uri`/api/v1/group/${id ?? 'none'}/leave`, {}),
      regenerateInvitationCode: () =>
        api(uri`/api/v1/group/${id ?? 'none'}/regenerate-invitation-code`, {}),
    },
    edit,
    events,
    group,
    person,
    personActions: {
      remove: (personId) => () =>
        api(uri`/api/v1/group/${id ?? 'none'}/remove`, { personId }),
      promote: (personId) => () =>
        api(uri`/api/v1/group/${id ?? 'none'}/promote`, { personId }),
      demote: (personId) => () =>
        api(uri`/api/v1/group/${id ?? 'none'}/demote`, { personId }),
    },
    refresh: () => {
      setEdit(false);
      refresh();
    },
  });
};

export default Controller;
