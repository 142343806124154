import { captureException } from '@sentry/react';
import axios from 'axios';
import type { Group } from 'core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import uri from 'uri-tag';
import { useCache } from '../../../providers/cache.js';
import type { ExistingEntityViewProps } from '../../../utils/types.js';
import type { Data } from './view.js';
import { JoinGroupView } from './view.js';

export const JoinGroup: FC<ExistingEntityViewProps<Group.Type>> = ({
  id,
  refresh,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { group } = useCache();

  const formik = useFormik<Data>({
    initialValues: {
      invitationCode: '',
    },
    validateOnBlur: true,
    onReset: refresh,
    onSubmit: async (body) => {
      try {
        const {
          data: { success },
        } = await axios.post<{ success: boolean }>(
          uri`/api/v1/group/${id}/join`,
          {
            invitationCode: body.invitationCode.toUpperCase(),
          },
        );
        if (success) {
          enqueueSnackbar('Joined group', { variant: 'success' });
        } else {
          enqueueSnackbar('Unable to join group', { variant: 'warning' });
        }
      } catch (err: unknown) {
        captureException(err);
        if (err instanceof Error) {
          enqueueSnackbar(`Unable to join: ${err.message}`, {
            variant: 'error',
          });
        } else {
          throw err;
        }
      }
      group.invalidate(id);
      refresh();
    },
  });

  return JoinGroupView({
    formik,
  });
};
