import {
  ArrowCircleDown as DemoteIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  MoreVert as MoreIcon,
  ArrowCircleUp as PromoteIcon,
  Refresh as RegenerateCodeIcon,
  Delete as RemoveIcon,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import type { Event, Group, PaginatedList, Person } from 'core';
import type { FC } from 'react';
import { PersonEntity } from '../../components/entity/person.js';
import { MenuController } from '../../components/menu-controller/menu-controller.js';
import { PageTitle } from '../../components/page-title/page-title.js';
import type { UseAxiosResult } from '../../hooks/use-axios.js';
import { EditDetails } from './edit/controller.js';
import { GroupEventsView } from './events.js';
import { JoinGroup } from './join/controller.js';
import { isAdmin, isMember } from './utils.js';

export type GroupActionNames = 'rename' | 'leave' | 'regenerateInvitationCode';
export type PersonActionNames = 'remove' | 'promote' | 'demote';

interface Props {
  edit: boolean;
  events: UseAxiosResult<PaginatedList<Event.WithCourses> | undefined>;
  data: Group.Type;
  groupActions: Record<GroupActionNames, () => void>;
  person: Person.Type;
  personActions: Record<PersonActionNames, (personId: string) => () => void>;
  refresh: () => void;
}

export const GroupDetailsView: FC<Props> = ({
  edit,
  events,
  data,
  groupActions,
  person,
  personActions,
  refresh,
}) => (
  <PageTitle title={data.title}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%' }}>
          <Collapse in={!edit}>
            <CardContent sx={{ mb: 2 }}>
              <Stack spacing={1}>
                <Box display="flex" flex={1} justifyContent="space-between">
                  <Typography variant="h3">{data.title}</Typography>
                  <MenuController
                    button={(handleClick) => (
                      <IconButton
                        onClick={handleClick}
                        sx={{ mt: '-4px !important', mr: -1 }}
                      >
                        <MoreIcon />
                      </IconButton>
                    )}
                    horizontalAlign="right"
                    items={[
                      {
                        key: 'rename',
                        hidden: !isAdmin(data, person),
                        icon: <EditIcon fontSize="small" />,
                        label: 'Rename',
                        onClick: groupActions.rename,
                      },
                      {
                        key: 'regenerate',
                        hidden: !isAdmin(data, person),
                        icon: <RegenerateCodeIcon fontSize="small" />,
                        label: 'Regenerate Invitation Code',
                        onClick: groupActions.regenerateInvitationCode,
                      },
                      {
                        key: 'leave',
                        confirm: true,
                        icon: <RemoveIcon fontSize="small" />,
                        label: 'Leave Group',
                        onClick: groupActions.leave,
                      },
                    ]}
                  />
                </Box>
              </Stack>
            </CardContent>
          </Collapse>
          <Collapse in={edit}>
            <EditDetails id={data._id} data={data} refresh={refresh} />
          </Collapse>
        </Card>
      </Grid>
      {isAdmin(data, person) || isMember(data, person) ? (
        <Grid container item xs={12} spacing={3} direction="row-reverse">
          <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              <Typography variant="h4">People</Typography>
              <Card>
                <CardContent>
                  <Stack spacing={3}>
                    <Typography variant="h3">Admins</Typography>
                    <Stack spacing={1}>
                      {data.admin?.personIds?.length ? (
                        data.admin?.personIds?.map((personId) => (
                          <Box
                            key={personId}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box>
                              <PersonEntity personId={personId} />
                            </Box>
                            {isAdmin(data, person) &&
                            person._id !== personId ? (
                              <Box sx={{ mr: -1 }}>
                                <MenuController
                                  button={(handleClick) => (
                                    <IconButton
                                      onClick={handleClick}
                                      size="small"
                                    >
                                      <MoreIcon />
                                    </IconButton>
                                  )}
                                  horizontalAlign="right"
                                  items={[
                                    {
                                      key: 'not-admin',
                                      icon: <DemoteIcon fontSize="small" />,
                                      label: 'Not Admin',
                                      onClick: personActions.demote(personId),
                                    },
                                  ]}
                                />
                              </Box>
                            ) : null}
                          </Box>
                        ))
                      ) : (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          fontStyle="italic"
                        >
                          There are currently no admins.
                        </Typography>
                      )}
                    </Stack>
                    <Typography variant="h3">Members</Typography>
                    <Stack spacing={1}>
                      {data.members?.length ? (
                        data.members?.map((personId) => (
                          <Box
                            key={personId}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box>
                              <PersonEntity personId={personId} />
                            </Box>
                            {isAdmin(data, person) &&
                            person._id !== personId ? (
                              <Box sx={{ mr: -1 }}>
                                <MenuController
                                  button={(handleClick) => (
                                    <IconButton
                                      onClick={handleClick}
                                      size="small"
                                    >
                                      <MoreIcon />
                                    </IconButton>
                                  )}
                                  horizontalAlign="right"
                                  items={[
                                    {
                                      key: 'promote',
                                      icon: <PromoteIcon fontSize="small" />,
                                      label: 'Make Admin',
                                      onClick: personActions.promote(personId),
                                    },
                                    {
                                      key: 'remove',
                                      confirm: true,
                                      icon: <RemoveIcon fontSize="small" />,
                                      label: 'Remove from Group',
                                      onClick: personActions.remove(personId),
                                    },
                                  ]}
                                />
                              </Box>
                            ) : null}
                          </Box>
                        ))
                      ) : (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          fontStyle="italic"
                        >
                          There are currently no members of this group.
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </CardContent>
                {data.invitationCode ? (
                  <Accordion sx={{ mt: 2 }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ px: 3 }}
                    >
                      <Typography variant="h6">Invite Members</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ px: 3 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        fontStyle="italic"
                      >
                        To add new members to this group, give them the URL as
                        well as this Invitation Code:
                      </Typography>
                      <Typography
                        fontFamily="monospace"
                        fontSize="2rem"
                        textAlign="center"
                      >
                        {data.invitationCode}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ) : null}
              </Card>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
              <Typography variant="h4">Events</Typography>
              <GroupEventsView events={events} person={person} />
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Stack spacing={3}>
            <Typography variant="h4">
              Do you have an invitation code?
            </Typography>
            <JoinGroup id={data._id} data={data} refresh={refresh} />
          </Stack>
        </Grid>
      )}
    </Grid>
  </PageTitle>
);
