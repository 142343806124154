import { Send as JoinIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import type { FormikProps } from 'formik';
import type { FC } from 'react';
import { FormikTextField } from '../../../components/formik-fields/text-field.js';

export interface Data {
  invitationCode: string;
}

interface Props {
  formik: FormikProps<Data>;
}

export const JoinGroupView: FC<Props> = ({ formik }) => (
  <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
    <Card>
      <CardContent>
        <Stack spacing={3}>
          <Typography variant="h6">Join Group</Typography>
          <Typography variant="body1">
            You can join this group by providing the invitation code. This code
            can be provided to you be another member.
          </Typography>
          <FormikTextField
            id="invitationCode"
            label="Invitation Code"
            formik={formik}
            fullWidth
            autoFocus
          />
        </Stack>
      </CardContent>

      <CardActions>
        <Box />
        <LoadingButton
          color="primary"
          loading={formik.isSubmitting}
          startIcon={<JoinIcon />}
          type="submit"
          variant="contained"
        >
          Join
        </LoadingButton>
      </CardActions>
    </Card>
  </form>
);
